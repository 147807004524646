import clsx from "clsx";

import { getPillar } from "../../../../utils/pillars";
import { RiQuestionLine } from "@remixicon/react";

const Title = (props) => {
  const { pillarNames, content, references, onInfo } = props;

  const iconClasses = (pillar) => {
    switch (pillar?.color) {
      case "green":
        return "text-green-600 bg-green-100 ring-green-400";
      case "yellow":
        return "text-yellow-600 bg-yellow-100 ring-yellow-400";
      case "blue":
        return "text-blue-600 bg-blue-100 ring-blue-400";
      case "orange":
        return "text-orange-600 bg-orange-100 ring-orange-400";
      case "purple":
        return "text-purple-600 bg-purple-100 ring-purple-400";
      case "red":
        return "text-red-600 bg-red-100 ring-red-400";
      case "stone":
        return "text-stone-600 bg-stone-100 ring-stone-400";
      case "cyan":
        return "text-cyan-600 bg-cyan-100 ring-cyan-400";
      default:
        return "text-slate-600 bg-slate-100 ring-slate-400";
    }
  };

  const buildIcon = (Icon) => {
    return !!Icon ? <Icon size={20} /> : <RiQuestionLine size={20} />;
  };

  const buildIcons = () => {
    const pillars = pillarNames.map((pillar) => getPillar(pillar));
    return (
      <>
        {pillars.map((pillar, idx) => (
          <span key={idx} className={clsx("ring-1 ring-inset rounded-full py-1.5 px-1.5", iconClasses(pillar))}>
            {buildIcon(pillar?.icon)}
          </span>
        ))}
      </>
    );
  };

  return (
    <div className="flex items-center w-full gap-x-2">
      <div className="flex gap-x-2">{buildIcons()}</div>
      <div className="cursor-pointer min-w-0 flex-auto">
        <p className="text-sm font-semibold leading-6 text-slate-900">
          <span onClick={onInfo}>{content}</span>
          <span className="ml-1 space-x-1">
            {references.map((r, idx) => (
              <a
                href={r}
                className="text-base font-normal text-indigo-700 hover:underline"
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <sup>[{idx + 1}]</sup>
              </a>
            ))}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Title;
