import { liteClient as algoliasearch } from "algoliasearch/lite";

import { InstantSearch } from "react-instantsearch";

import CustomSearchInput from "../components/Dashboard/Search/CustomSearchInput";
import RecommendationResult from "../components/Dashboard/Search/Recommendations/RecommendationResult";
import SearchResultList from "../components/Dashboard/Search/SearchResultList";

const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID, process.env.REACT_APP_ALGOLIA_KEY);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: "",
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const DashboardPage = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.REACT_APP_ACTION_INDEX}
      insights={true}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <div className="flex flex-col w-full">
        <div className="flex w-full justify-start p-6 bg-indigo-50 shadow">
          <div className="flex justify-center w-full">
            <div className="flex flex-col w-full">
              <div className="flex justify-center">
                <CustomSearchInput />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-5 mx-2 lg:mx-10 items-center">
          <div className="flex flex-col items-center lg:w-3/4 bg-white shadow-sm ring-1 ring-slate-900/5 rounded-xl mb-5">
            <SearchResultList resultComponent={RecommendationResult} />
          </div>
        </div>
      </div>
    </InstantSearch>
  );
};

export default DashboardPage;
