import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { useInstantSearch } from "react-instantsearch";

import { ApiContext } from "../../../providers/ApiProvider";

import { classNames } from "../../../utils/cssUtils";

import LoadingSpinner from "../../UI/LoadingSpinner";

import CustomSearchInput from "../../Dashboard/Search/CustomSearchInput";
import SearchResultList from "../../Dashboard/Search/SearchResultList";

import ActionItem from "./ActionItem";
import ActionItemResult from "./ActionItemResult";

const ActionPlan = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  const { results: searchResults } = useInstantSearch();

  const [isAddNew, setIsAddNew] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingItem, setIsSavingItem] = useState({});
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/patients/${patientId}/recommendations`)
      .then((resp) => {
        setRecommendations(resp.data.patients_recommendations);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, patientId]);

  useEffect(() => {
    setHasSearchResults(searchResults.hits.length > 0);
  }, [searchResults.hits]);

  const handleAdd = (data) => {
    setIsAddNew(false);
    setIsLoading(true);
    const params = {};
    if (data.recommendation_id && data.recommendation_id !== -1) {
      params.recommendation_id = data.recommendation_id;
    } else {
      params.recommendation_id = data.id === -1 ? null : data.id;
      params.content = data.content;
    }

    api.client
      .post(`/patients/${patientId}/recommendations`, params)
      .then((resp) => {
        if (resp.status === 204) {
          toast.warning("Action already added to patient.");
        } else {
          setRecommendations((prev) => [...prev, resp.data.patients_recommendation]);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = (data) => {
    setIsSavingItem((prev) => ({ ...prev, [data.id]: true }));
    api.client
      .put(`/patients/${patientId}/recommendations/${data.id}`, {
        content: data.content,
      })
      .then((resp) => {
        setRecommendations((prev) => {
          const res = prev.map((r) => {
            if (r.id === data.id) {
              return resp.data.patients_recommendation;
            }
            return r;
          });
          return [...res];
        });
        setIsSavingItem((prev) => ({ ...prev, [data.id]: false }));
      })
      .catch(() => {
        setIsSavingItem((prev) => ({ ...prev, [data.id]: false }));
      });
  };

  const handleDelete = (id) => {
    setIsSavingItem((prev) => ({ ...prev, [id]: true }));
    api.client
      .delete(`/patients/${patientId}/recommendations/${id}`)
      .then(() => {
        setRecommendations((prev) => prev.filter((r) => r.id !== id));
        setIsSavingItem((prev) => ({ ...prev, [id]: false }));
      })
      .catch(() => {
        setIsSavingItem((prev) => ({ ...prev, [id]: false }));
      });
  };

  const handleCreateOrUpdate = (data) => {
    if (data.id && data.id !== -1) {
      handleUpdate(data);
    } else {
      handleAdd(data);
    }
  };

  return (
    <>
      <div className="mt-5 mx-5 flex flex-col items-center">
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full">
            <div className="flex justify-center">
              <CustomSearchInput filterItems={recommendations.map((r) => r.id)} />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "flex flex-row-reverse gap-x-5 justify-center mt-5",
            hasSearchResults ? "w-full" : "w-3/4 "
          )}
        >
          <div
            className={classNames(
              "flex py-5 px-3 justify-center bg-white shadow ring-1 ring-slate-300 rounded-lg",
              hasSearchResults ? "w-2/5" : "w-full "
            )}
          >
            {isLoading ? (
              <LoadingSpinner color="text-indigo-500" />
            ) : (
              <div className="relative w-full">
                <ul className="divide-y divide-slate-100 gap-y-2">
                  {recommendations.map((r) => (
                    <ActionItem
                      key={r?.id}
                      item={r}
                      onDelete={handleDelete}
                      onSave={handleCreateOrUpdate}
                      isLoading={isSavingItem[r?.id]}
                    />
                  ))}

                  {isAddNew ? (
                    <ActionItem
                      item={{ id: -1 }}
                      onDelete={handleDelete}
                      onSave={handleCreateOrUpdate}
                      isLoading={isSavingItem[-1]}
                      onCancel={() => setIsAddNew(false)}
                    />
                  ) : (
                    <li className="relative p-4 w-full flex flex-col items-center justify-between">
                      <button
                        onClick={() => setIsAddNew(true)}
                        type="button"
                        className="inline-flex w-full px-4 py-2 text-sm items-center justify-center font-medium shadow-sm rounded-md text-indigo-800 ring-1 ring-indigo-400 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-100"
                      >
                        Add Custom Recommendation
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          {hasSearchResults && (
            <div className="flex py-5 w-3/5 justify-center bg-white shadow ring-1 ring-slate-300 rounded-lg">
              <SearchResultList resultComponent={ActionItemResult} onAdd={handleAdd} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ActionPlan;
