import { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { liteClient as algoliasearch } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";

import { ApiContext } from "../providers/ApiProvider";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import PatientDetailsHeader from "../components/Patients/Details/PatientDetailsHeader";

import ActionPlan from "../components/Patients/ActionPlan/ActionPlan";

const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID, process.env.REACT_APP_ALGOLIA_KEY);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: "",
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const PatientsDetailsPage = () => {
  const api = useContext(ApiContext);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [patient, setPatient] = useState();

  useEffect(() => {
    api.client
      .get(`/patients/${id}`)
      .then((resp) => {
        setPatient(resp.data.patient);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [api.client, id]);

  const handleUpdateDetails = (details) => {
    setPatient(details);
  };

  return (
    <div className="mx-auto w-full max-w-[2000px]">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="mt-5 mx-5 flex justify-center">
            <PatientDetailsHeader patient={patient} onUpdateDetails={handleUpdateDetails} />
          </div>
          <InstantSearch
            searchClient={searchClient}
            indexName={process.env.REACT_APP_ACTION_INDEX}
            insights={true}
            future={{ preserveSharedStateOnUnmount: true }}
          >
            <ActionPlan patientId={id} />
          </InstantSearch>
        </>
      )}
    </div>
  );
};

export default PatientsDetailsPage;
