import { useState } from "react";

import { RiArticleFill, RiArticleLine, RiUserAddFill, RiUserAddLine, RiUserForbidLine } from "@remixicon/react";

const Actions = (props) => {
  const { onAdd, onInfo, canAdd = true, showAdd = true } = props;

  const [hoverAdd, setHoverAdd] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(false);

  return (
    <div className="flex shrink-0 items-center gap-x-4">
      <span
        onMouseEnter={() => setHoverInfo(true)}
        onMouseLeave={() => setHoverInfo(false)}
        onClick={onInfo}
        className="cursor-pointer text-slate-800 z-30"
      >
        {hoverInfo ? <RiArticleFill size={22} /> : <RiArticleLine size={22} />}
      </span>
      {showAdd && (
        <span
          onMouseEnter={() => setHoverAdd(true)}
          onMouseLeave={() => setHoverAdd(false)}
          onClick={onAdd}
          disabled={!canAdd}
          data-tooltip-hidden={canAdd}
          data-tooltip-id="actions-tooltip"
          data-tooltip-content="Upgrade to our premium plan to manage patients"
          className="cursor-pointer text-indigo-800 z-30"
        >
          {canAdd ? (
            <>{hoverAdd ? <RiUserAddFill size={22} /> : <RiUserAddLine size={22} />}</>
          ) : (
            <RiUserForbidLine size={22} />
          )}
        </span>
      )}
    </div>
  );
};

export default Actions;
