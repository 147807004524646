import { forwardRef, useState } from "react";

import TextArea from "../../../UI/TextArea";

const EditRecommendation = forwardRef(function EditRecommendation(props, ref) {
  const { recommendation, onChange } = props;

  const [expand, setExpand] = useState(false);

  console.log(recommendation);

  const handleChange = (value) => {
    if (!!onChange && typeof onChange === "function") {
      onChange(value);
    }
  };

  return (
    <div className="w-full">
      <TextArea
        ref={ref}
        rows={expand ? 4 : 1}
        value={recommendation?.content || recommendation?.recommendation?.content}
        onFocus={() => setExpand(true)}
        onBlur={() => setExpand(false)}
        onChange={handleChange}
      />
    </div>
  );
});

export default EditRecommendation;
