import { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";

import { Tooltip } from "react-tooltip";
import { Toaster } from "sonner";

import { AuthContext } from "../../providers/AuthProvider";

import AnonTopNav from "./AnonTopNav";
import Footer from "../UI/Footer";

const AnonLayout = () => {
  const auth = useContext(AuthContext);

  if (auth.isLoggedIn()) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="bg-white flex flex-col h-screen justify-between">
      <AnonTopNav />
      <main>
        <Toaster richColors position="top-center" />
        <Outlet />
      </main>
      <Footer />
      <Tooltip id="anon-tooltip" />
    </div>
  );
};

export default AnonLayout;
