import axios from "axios";

import { useContext, useEffect, useState } from "react";

import { Navigate, Outlet } from "react-router-dom";

import Cookies from "universal-cookie";

import { Toaster } from "sonner";

import { useInterval } from "../../hooks/useInterval";

import { AuthContext } from "../../providers/AuthProvider";

import Banner from "../UI/Banner";

import TopNav from "./TopNav";
import Footer from "../UI/Footer";
import LoadingSpinner from "../UI/LoadingSpinner";

const version = process.env.REACT_APP_VERSION;

const TEN_MINUTES = 1000 * 60 * 10;

const AuthedLayout = () => {
  const auth = useContext(AuthContext);

  const cookies = new Cookies(null, { path: "/" });

  const [hideBetaBanner, setHideBetaBanner] = useState(cookies.get("hideBetaBanner") || false);
  const [showUpdateBanner, setShowUpdateBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useInterval(() => {
    if (process.env.NODE_ENV === "development") return;

    axios
      .get("https://app-version.propatienttech.com/propatienttech")
      .then((resp) => {
        if (resp.data.version !== version) {
          setShowUpdateBanner(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, TEN_MINUTES);

  useEffect(() => {
    setIsLoading(true);
    auth
      .currentUser()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [auth]);

  if (!auth.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  const handleHideBetaBanner = () => {
    cookies.set("hideBetaBanner", true);
    setHideBetaBanner(true);
  };

  return (
    <>
      {!hideBetaBanner && (
        <Banner onDismiss={handleHideBetaBanner}>
          This platform is in the beta testing stage right now. We expect it to have some problems with the way it
          works. Rest assured, we are on high alert. We will be rapidly fixing any issues as we become aware of them. If
          you encounter a problem or would like to leave a comment, please email us at{" "}
          <a href="mailto:help@propatienttech.com" className="underline hover:font-semibold">
            help@propatienttech.com
          </a>
          . We will get back to you within 24 hours.
        </Banner>
      )}
      <div className="flex flex-col h-screen justify-between">
        <div className="flex bg-slate-100">
          <div className="flex flex-col flex-1">
            {!isLoading && <TopNav />}

            <main className="flex relative focus:outline-none">
              <Toaster richColors position="top-center" />
              {isLoading ? (
                <div className="flex w-full h-full justify-center items-center">
                  <LoadingSpinner color="text-indigo-600" />
                </div>
              ) : (
                <Outlet />
              )}
            </main>
          </div>
        </div>
        <Footer />
      </div>
      {showUpdateBanner && (
        <div className="pointer-events-none fixed inset-x-0 bottom-20 flex justify-center px-8 pb-5">
          <div className="text-white pointer-events-auto flex items-center justify-between gap-x-6 bg-indigo-700 px-6 py-3 rounded-xl">
            A new version of this page is available.
            <button
              type="button"
              onClick={() => window.location.reload()}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-100"
            >
              Please Refresh
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthedLayout;
