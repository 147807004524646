import { RiHeart3Fill, RiArrowRightSLine } from "@remixicon/react";

const About = () => {
  return (
    <div id="about" className="flex flex-col items-center bg-white pt-8 pb-10 px-8 lg:px-36">
      <div className="flex flex-col lg:flex-row max-w-6xl items-center w-full gap-x-10 mb-10">
        <div className=" text-rose-500">
          <RiHeart3Fill className="h-16 w-16 lg:h-24 lg:w-24" />
        </div>
        <div className="prose text-slate-600 font-semibold text-3xl">
          We started Pro-Patient Tech to help Lifestyle Medicine practitioners and patients to manage their health. We
          build tools that help practitioners scale their practice by having the tech get out of the way. It's time
          technology served the physician and not the other way around.
        </div>
      </div>
      <div className="mt-5 flex flex-col lg:flex-row max-w-6xl gap-6">
        <div className="flex min-w-64">
          <img
            alt="Dr. Nupur Garg"
            src="images/garg.jpg"
            className="aspect-[4/5] w-64 flex-none rounded-2xl object-cover"
          />
        </div>
        <div className="flex flex-col gap-y-5">
          <div className="flex flex-row gap-3 text-base leading-6 text-slate-700">
            Dr. Nupur Garg completed her undergraduate studies at MIT and her medical training at Yale, she became
            board-certified in Emergency Medicine. However, her passion for helping patients led her to explore the
            transformative power of lifestyle interventions. Through her research on healthy diets for her young family,
            Dr. Garg discovered the profound impact that lifestyle factors can have on overall health and wellbeing.
            This realization inspired her to become board-certified in Lifestyle Medicine and start her own Lifestyle
            Medicine clinic. She has authored the book "Guidebook to Ace the Lifestyle Medicine Boards", the first on
            the topic. In addition to the book, Dr. Garg teaches the only US-based Lifestyle Medicine board review
            course.
            <div>
              <a href="https://www.amazon.com/Guidebook-Ace-Lifestyle-Medicine-Boards/dp/B0D5RDLNZV">
                <img alt="" src="images/book.jpg" className="h-auto max-w-36" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-4xl mt-16 mb-3">
        <button
          type="button"
          disabled={true}
          data-tooltip-id="anon-tooltip"
          data-tooltip-content="Registration coming soon"
          className="inline-flex items-center min-w-64 px-6 py-6 lg:text-xl bg-indigo-600 text-white hover:text-slate-50 hover:bg-indigo-500 active:bg-indigo-800 text-base rounded-full"
          // href="/register?plan=free"
        >
          Get All Recommendations
          <RiArrowRightSLine size={24} aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default About;
