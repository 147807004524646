import { useEffect, useState } from "react";

import { useRefinementList } from "react-instantsearch";

import { RiCloseCircleFill, RiCloseCircleLine } from "@remixicon/react";

import { pillars } from "../../../utils/pillars";

import FilterItem from "./FilterItem";

const Filter = () => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [counts, setCounts] = useState({});
  const [hoverClear, setHoverClear] = useState(false);

  const { items, refine } = useRefinementList({
    limit: 100,
    attribute: "pillars",
    operator: "and",
  });

  useEffect(() => {
    const res = {};
    items.forEach((item) => {
      res[item.value] = item.count;
    });
    setCounts(res);
  }, [items]);

  const handleFilterChange = (key) => {
    setActiveFilters((prev) => {
      if (prev.indexOf(key) === -1) {
        return [...prev, key];
      } else {
        return [...prev.filter((filter) => filter !== key)];
      }
    });

    refine(key);
  };

  const clearFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();

    activeFilters.forEach((filter) => {
      refine(filter);
    });
    setActiveFilters([]);
  };

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
        <div className="flex justify-start h-full gap-x-2 items-center">
          <h3 className="text-sm font-medium text-slate-500">
            Filters
            <span className="sr-only">, active</span>
          </h3>
          {activeFilters.length === 0 && <span className="w-6" />}
          {activeFilters.length > 0 && (
            <button
              type="button"
              className="text-slate-500"
              onMouseEnter={() => setHoverClear(true)}
              onMouseLeave={() => setHoverClear(false)}
              onClick={clearFilters}
            >
              {hoverClear ? <RiCloseCircleFill size={24} /> : <RiCloseCircleLine size={24} />}
            </button>
          )}
        </div>

        <div className="mt-2 sm:ml-4 sm:mt-0 pl-2 border-l border-slate-300">
          <div className="-m-1 flex flex-wrap items-center">
            {Object.values(pillars).map((pillar) => (
              <FilterItem
                key={pillar.label}
                pillar={pillar}
                count={counts[pillar.name] || 0}
                isActive={activeFilters.includes(pillar.name)}
                onChange={() => handleFilterChange(pillar.name)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
