import { useRef, useState } from "react";

import { useInstantSearch, useSearchBox } from "react-instantsearch";

import { RiCloseCircleFill, RiCloseCircleLine, RiSearchLine } from "@remixicon/react";

import LoadingSpinner from "../../UI/LoadingSpinner";
import Filter from "./Filter";

const CustomSearchInput = (props) => {
  const { filterItems } = props;

  const { status } = useInstantSearch();
  const { query, refine, clear } = useSearchBox(props);

  const [inputValue, setInputValue] = useState(query);
  const [hoverClear, setHoverClear] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const inputRef = useRef(null);

  const isSearchStalled = status === "stalled";

  const setQuery = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setInputValue(e.currentTarget.value);
    refine(e.currentTarget.value);

    if (e.currentTarget.value.length === 0) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setInputValue("");
    clear();
    setShowFilter(false);
  };

  return (
    <>
      <div className="flex flex-col w-full lg:w-3/4">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            ref={inputRef}
            id="search"
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            maxLength={512}
            placeholder="Search Lifestyle Medicine Content"
            className="block w-full pl-4 text-base leading-6 rounded-md border-0 bg-slate-50 focus:bg-white py-3.5 pr-10 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            value={inputValue}
            onChange={setQuery}
            onBlur={setQuery}
          />
          <div className="absolute inset-y-0 right-0 flex items-center px-3">
            {isSearchStalled ? (
              <LoadingSpinner small color="text-indigo-800" showLoadingText={false} />
            ) : !!query ? (
              <span
                onMouseEnter={() => setHoverClear(true)}
                onMouseLeave={() => setHoverClear(false)}
                onClick={handleClear}
                className="cursor-pointer text-slate-600"
              >
                {hoverClear ? <RiCloseCircleFill size={24} /> : <RiCloseCircleLine size={24} />}
              </span>
            ) : (
              <RiSearchLine size={24} className="text-slate-600" />
            )}
          </div>
        </div>
        {showFilter && <Filter />}
      </div>
    </>
  );
};

export default CustomSearchInput;
