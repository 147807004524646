import { useContext, useState } from "react";

import { ApiContext } from "../../../providers/ApiProvider";

import { calcAge, buildSex } from "../../../utils/patientUtils";

import DetailsSlideOver from "../../UI/DetailsSlideOver";

import PatientDetails from "./PatientDetails";
import LoadingSpinner from "../../UI/LoadingSpinner";

const PatientDetailsHeader = (props) => {
  const { patient, onUpdateDetails } = props;

  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const patientName = () => {
    if (patient.preferred_name) {
      return `${patient.first_name} "${patient.preferred_name}" ${patient.last_name}`;
    } else {
      return patient.name;
    }
  };

  const handleUpdateDetails = (data) => {
    setIsLoading(true);
    api.client
      .put(
        `/patients/${patient.id}`,
        { patient: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setIsLoading(false);
        onUpdateDetails(resp.data.patient);
        setShowDetails(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const patientDetails = () => {
    const age = calcAge(patient.dob);
    const sex = buildSex(patient);

    return (
      <div className="flex space-x-5">
        <span>{patientName()}</span> <span>{sex}</span> <span>{age}</span>
      </div>
    );
  };

  return (
    <div className="flex justify-center w-3/4">
      <div className="w-full mx-auto px-6 py-2 flex items-center justify-between space-x-5">
        <div className="flex items-center justify-start space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              {patient.photo ? (
                <img className="h-16 w-16 rounded-full" src={patient.photo} alt="" />
              ) : (
                <span className="inline-block overflow-hidden h-16 w-16 rounded-full bg-slate-100">
                  <svg className="h-full w-full text-slate-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
              )}
              <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-slate-900">{patientDetails()}</h1>
          </div>
        </div>
        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <button
            onClick={() => setShowDetails(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-slate-100"
          >
            Patient Details
          </button>
        </div>
      </div>

      {showDetails && (
        <DetailsSlideOver open={showDetails} onClose={() => setShowDetails(false)} title="Patient Details">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <PatientDetails patient={patient} onClose={() => setShowDetails(false)} onChange={handleUpdateDetails} />
          )}
        </DetailsSlideOver>
      )}
    </div>
  );
};

export default PatientDetailsHeader;
