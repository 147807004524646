import clsx from "clsx";

import { useState } from "react";

import RecommendationResources from "../../Dashboard/Search/Recommendations/RecommendationResources";
import Title from "../../Dashboard/Search/Recommendations/Title";
import Actions from "../../Dashboard/Search/Recommendations/Actions";

const ActionItemResult = (props) => {
  const { item, onAdd } = props;

  const [showInfo, setShowInfo] = useState(false);

  const infoClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowInfo((prev) => !prev);
  };

  const addClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onAdd({ recommendation_id: item.id });
  };

  return (
    <li
      className={clsx(
        "relative flex flex-col px-6 py-3 ",
        showInfo ? "ring-1 ring-indigo-200 rounded-md shadow-md" : "hover:bg-slate-50"
      )}
    >
      <div className="flex justify-between gap-x-6">
        <Title pillarNames={item.pillars} content={item.content} references={item.references} onInfo={infoClick} />
        <Actions onAdd={addClick} onInfo={infoClick} />
      </div>
      {showInfo && <RecommendationResources resources={item.resources} reviewedAt={item.reviewed_at} />}
    </li>
  );
};

export default ActionItemResult;
