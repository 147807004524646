import { useContext, useEffect, useState } from "react";

import { RiArrowRightSLine, RiCloseCircleLine, RiSearchLine } from "@remixicon/react";

import { ApiContext } from "../../providers/ApiProvider";
import RecommendationResult from "../Dashboard/Search/Recommendations/RecommendationResult";

const Hero = () => {
  const api = useContext(ApiContext);

  const [query, setQuery] = useState("");
  const [hits, setHits] = useState([]);

  useEffect(() => {
    if (query.length === 0) {
      setHits([]);
      return;
    }

    if (query.length < 2) return;

    api.client.get("/recommendations", { params: { anon: true, query } }).then((resp) => {
      setHits(resp.data.recommendations);
    });
  }, [api.client, query]);

  const handleSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (query.length > 0) {
      setQuery("");
      setHits([]);
    }
  };

  return (
    <div className="mx-auto max-w-7xl px-4 lg:px-8 pb-16 pt-12 text-center lg:pt-32">
      <h1 className="mx-auto max-w-3xl font-display text-3xl font-medium tracking-tight text-slate-900 sm:text-6xl">
        Lifestyle Medicine toolbox to{" "}
        <span className="relative whitespace-nowrap text-indigo-600">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            className="absolute left-0 top-2/3 h-[0.58em] w-full fill-indigo-300/70"
            preserveAspectRatio="none"
          >
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
          </svg>
          <span className="relative">scale your practice</span>
        </span>{" "}
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-xl lg:text-3xl lg:font-semibold tracking-tight text-slate-600">
        Empower your patients to achieve real results.
      </p>
      <div className="flex flex-col mx-auto max-w-4xl mt-2 lg:mt-5">
        <div className="mt-2 mx-auto w-full flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              id="search"
              name="search"
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for action items..."
              className="block w-full text-base lg:text-lg sm:leading-6 rounded-none rounded-l-md border-0 py-2.5 lg:py-4 px-3 lg:pl-5 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            />
          </div>
          <button
            type="button"
            onClick={handleSearch}
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 lg:px-6 py-1.5 text-sm font-semibold text-white ring-1 ring-inset ring-indigo-700 bg-indigo-700 hover:bg-indigo-500"
          >
            {query.length > 0 ? (
              <RiCloseCircleLine size={28} aria-hidden="true" className="text-indigo-100" />
            ) : (
              <RiSearchLine size={28} aria-hidden="true" className="text-indigo-100" />
            )}
          </button>
        </div>
        <div className="text-xs text-slate-400 mt-1">
          By using the search, you agree to review the evidence yourself and take full responsibility for any
          recommendations you follow or provide to others.
        </div>
      </div>
      {!!hits && hits.length > 0 && (
        <div className="flex flex-col mx-auto items-center max-w-4xl mt-2 lg:mt-5">
          <div className="flex flex-col items-center text-left bg-white shadow-md ring-1 ring-slate-300 rounded-xl mb-5">
            <ul className="w-full divide-y divide-slate-100 py-3 px-2">
              {hits.map((hit) => (
                <RecommendationResult key={hit.id} item={hit} showAddToPatient={false} />
              ))}
            </ul>
          </div>
        </div>
      )}
      {(!hits || hits.length === 0) && query.length > 0 && (
        <div className="flex flex-col mx-auto items-center max-w-4xl mt-2 lg:mt-5">
          <div className="flex flex-col items-center text-left bg-white shadow-md ring-1 ring-slate-300 rounded-xl mb-5">
            <div className="relative flex flex-col items-center p-5">
              <button
                type="button"
                onClick={handleSearch}
                className="absolute top-2 right-2 text-slate-400 hover:text-slate-600"
              >
                <RiCloseCircleLine size={24} aria-hidden="true" />
              </button>
              <h2 className="text-xl font-semibold text-slate-900">No results found</h2>
              <p className="text-base text-slate-600 mt-2">
                We couldn't find any recommendations based on your search. Please try another search term.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="pt-8">
        <button
          type="button"
          disabled={true}
          data-tooltip-id="anon-tooltip"
          data-tooltip-content="Registration coming soon"
          className="inline-flex items-center min-w-64 px-6 py-6 lg:text-xl bg-indigo-600 text-white hover:text-slate-50 hover:bg-indigo-500 active:bg-indigo-800 text-base rounded-full"
          href="/register?plan=free"
        >
          All Recommendations, Free
          <RiArrowRightSLine size={24} aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default Hero;
