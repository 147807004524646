import clsx from "clsx";

import { useRef, useState } from "react";

import {
  RiArticleFill,
  RiArticleLine,
  RiDeleteBinFill,
  RiDeleteBinLine,
  RiEdit2Fill,
  RiEdit2Line,
} from "@remixicon/react";

import CancelButton from "../../UI/CancelButton";
import DeleteButton from "../../UI/DeleteButton";
import LoadingSpinner from "../../UI/LoadingSpinner";
import SaveButton from "../../UI/SaveButton";
import TextArea from "../../UI/TextArea";

import RecommendationResources from "../../Dashboard/Search/Recommendations/RecommendationResources";
import Title from "../../Dashboard/Search/Recommendations/Title";

const Actions = (props) => {
  const { onDelete, onEdit, onDetails } = props;

  const [hoverArticle, setHoverArticle] = useState(false);
  const [hoverDelete, setHoverDelete] = useState(false);
  const [hoverEdit, setHoverEdit] = useState(false);

  return (
    <div className="flex flex-none items-center gap-x-4">
      <span
        onMouseEnter={() => setHoverArticle(true)}
        onMouseLeave={() => setHoverArticle(false)}
        onClick={onDetails}
        className="cursor-pointer text-slate-800"
      >
        {hoverArticle ? <RiArticleFill className="h-5 w-5" /> : <RiArticleLine className="h-5 w-5" />}
      </span>
      <span
        onMouseEnter={() => setHoverDelete(true)}
        onMouseLeave={() => setHoverDelete(false)}
        onClick={onDelete}
        className="cursor-pointer text-red-800"
      >
        {hoverDelete ? <RiDeleteBinFill className="h-5 w-5" /> : <RiDeleteBinLine className="h-5 w-5" />}
      </span>
      <span
        onMouseEnter={() => setHoverEdit(true)}
        onMouseLeave={() => setHoverEdit(false)}
        onClick={onEdit}
        className="cursor-pointer text-indigo-800"
      >
        {hoverEdit ? <RiEdit2Fill className="h-5 w-5" /> : <RiEdit2Line className="h-5 w-5" />}
      </span>
    </div>
  );
};

const ActionItem = (props) => {
  const { item, onDelete, onSave, isLoading, onCancel } = props;

  const content = item?.content || item?.recommendation?.content;

  const [showDetails, setShowDetails] = useState(false);
  const [showEditing, setShowEditing] = useState(item.id === -1); // show editing form for new items
  const [isFormValid, setIsFormValid] = useState(false);

  const contentRef = useRef();

  const handleDelete = (e) => {
    e.preventDefault();
    onDelete(item.id);
  };

  const handleSubmit = () => {
    setShowEditing(false);

    if (content === contentRef.current.value) {
      return;
    }

    onSave({ ...item, content: contentRef.current.value });
  };

  const handleCancelEditing = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowEditing(false);

    if (!!onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <>
      <li
        className={clsx(
          "relative flex flex-col items-center justify-between transition-colors duration-200",
          showDetails || showEditing
            ? "ring-1 ring-indigo-200 rounded-md shadow-md"
            : "hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 "
        )}
      >
        {isLoading && (
          <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <LoadingSpinner small showLoadingText={false} color="text-indigo-600" />
          </div>
        )}
        {showEditing ? (
          <div className="flex flex-col w-full">
            <div className="p-3">
              <TextArea
                placeHolder="Add custom recommendation"
                ref={contentRef}
                rows={3}
                value={content}
                onChange={(value) => setIsFormValid(!!value)}
              />
            </div>
            <div className="px-4 py-4">
              <div className="flex justify-end space-x-3">
                {onDelete !== undefined && onDelete !== null && <DeleteButton onClick={handleDelete} />}
                <CancelButton onClick={handleCancelEditing} />
                <SaveButton onClick={handleSubmit} disabled={!isFormValid} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full p-2 items-center justify-between">
            <Title
              pillarNames={item.recommendation?.pillars || []}
              content={content}
              references={item?.recommendation?.references || []}
              onInfo={() => setShowDetails((p) => !p)}
            />
            <Actions
              onDelete={handleDelete}
              onEdit={() => setShowEditing(true)}
              onDetails={() => setShowDetails((p) => !p)}
            />
          </div>
        )}
        {showDetails && (
          <RecommendationResources
            resources={item.recommendation?.resources || []}
            reviewedAt={item?.recommendation?.reviewed_at}
          />
        )}
      </li>
    </>
  );
};

export default ActionItem;
