import { useContext, useEffect, useState } from "react";

import {
  ArchiveBoxIcon,
  BuildingOfficeIcon,
  CreditCardIcon,
  UsersIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";

import { ApiContext } from "../providers/ApiProvider";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import PageSectionTabs from "../components/UI/PageSectionTabs";
import ClinicDetails from "../components/Clinic/ClinicDetails";
import ClinicUsers from "../components/Clinic/ClinicUsers";
import Subscription from "../components/Clinic/Subscription";
import Data from "../components/Clinic/Data";

const ClinicPage = () => {
  const tabs = {
    details: { name: "Clinic Details", icon: BuildingOfficeIcon, value: 0 },
    // users: { name: "Users", icon: UsersIcon, value: 1 },
    subscription: { name: "Plan & Billing", icon: CreditCardIcon, value: 2 },
    data: { name: "Data", icon: ArchiveBoxIcon, value: 3 },
    // membership: {
    //   name: "Membership Dashboard",
    //   icon: UserGroupIcon,
    //   value: 4,
    //   href: "https://dashboard.stripe.com",
    //   target: "_blank",
    // },
  };

  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [clinic, setClinic] = useState();
  const [curTab, setCurTab] = useState(tabs.details);

  useEffect(() => {
    api.client
      .get("/organizations")
      .then((resp) => {
        setClinic(resp.data.organization);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client]);

  const handleClinicUpdate = (data) => {
    setIsLoading(true);
    api.client
      .put(
        "/organizations",
        { organization: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setClinic(resp.data.organization);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full mx-auto max-w-7xl rounded-lg bg-white shadow">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="px-4 py-5 sm:px-6">
            <div className="border-b border-gray-200 pb-5 sm:pb-0">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <div className="relative">
                    {clinic.logo ? (
                      <img className="h-16 w-16 rounded-full" src={clinic.logo} alt="" />
                    ) : (
                      <span className="inline-block overflow-hidden h-16 w-16 rounded-full bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                            />
                          </svg>
                        </svg>
                      </span>
                    )}
                    <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">{clinic.name}</h3>
                </div>
              </div>
              <div className="mt-3 sm:mt-4">
                <PageSectionTabs tabs={Object.values(tabs)} curTab={curTab} onChange={(tab) => setCurTab(tab)} />
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <>
              {curTab.value === tabs.details.value && <ClinicDetails clinic={clinic} onUpdate={handleClinicUpdate} />}
              {/* {curTab.value === tabs.users.value && <ClinicUsers />} */}
              {curTab.value === tabs.subscription.value && <Subscription clinic={clinic} />}
              {curTab.value === tabs.data.value && <Data />}
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default ClinicPage;
